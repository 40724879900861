import React from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// const DashboardLayout = lazy(() => import("examples/LayoutContainers/DashboardLayout"));
// const DashboardNavbar = lazy(() => import("examples/Navbars/DashboardNavbar")); // Adjust the import based on your file structure

const LayoutWrapper = ({ children }) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {children}
    </DashboardLayout>
  );
};

export default LayoutWrapper;
LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
